import * as Payments from './payments'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

export function VevoPayProcessing() {
  const transactionContext = Payments.useTransactionContext()

  return (
    <ThemeUi.Flex
      sx={{
        'alignItems': 'center',
        'justifyContent': 'center',
        'flexDirection': 'column',
        'height': '100%',
        '#nodapay-payment-system': {
          height: '100%',
          width: '100%',
          maxWidth: '600px',
        },
      }}
    >
      <iframe
        src={transactionContext?.redirectParams?.url}
        sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
        style={{border: 'none'}}
      />
    </ThemeUi.Flex>
  )
}
