import * as Animations from './animations'
import * as Configuration from './configuration'
import * as Constants from './constants'
import * as Data from './data'
import * as Forms from '@rushplay/forms'
import * as Hooks from './hooks'
import * as Payments from './payments'
import * as Processes from '@rushplay/processes'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {Cancel} from './cancel'
import {Failure} from './failure'
import {Menu} from './menu'
import {MissingQueries} from './missing-queries'
import {PaymentDetails} from './payment-details'
import {PaymentMethods} from './payment-methods'
import {Pending} from './pending'
import PropTypes from 'prop-types'
import {Success} from './success'
import {TransactionProcessing} from './transaction-processing'
import {UnsupportedTransactionType} from './unsupported-transaction-type'
import {useTransactionLogging} from './use-transaction-logging'

/**
 * Payments-Schema selector creator
 * @returns {function} Redux selector
 */
const getSchema = Payments.createSchemaSelector(Payments.schema)

/**
 * Attributes selector for TransactionProvider
 * @params state Redux state
 * @returns {Object} attributes needed for transations
 */
function getAttributes(state) {
  return {
    brand_key: Configuration.getBrand(state),
    client_type: Configuration.getClientType(state),
    channel_id: Configuration.getClientType(state),
    deposit_offer_package_id: Configuration.getOfferId(state),
    seon_session: Configuration.getSeonSession(state),
  }
}

async function fetchServerSideVersion() {
  const response = await fetch('/version')
  if (!response.ok) {
    throw new Error('Fetch failed')
  }
  const version = await response.json()
  return version
}

/**
 * Component to handle Transaction flow & payment-data gathering
 * @component Transaction
 * @returns {ReactNode} Transaction Provider
 */
export function Transaction() {
  const [step, setStep] = React.useState(
    Constants.TransactionStep.PaymentMethod
  )
  const [loading, setLoading] = React.useState(true)
  const firstUpdate = React.useRef(true)

  const {transactionType} = ReactRouter.useParams()

  const dispatch = ReactRedux.useDispatch()
  const attributes = ReactRedux.useSelector(getAttributes)
  const currency = ReactRedux.useSelector(Configuration.getCurrency)
  const dataSchema = ReactRedux.useSelector((state) =>
    getSchema(state.payments, {transactionType, currency})
  )
  const origin = ReactRedux.useSelector(Configuration.getOrigin)
  const isMissingRequiredQueries = ReactRedux.useSelector(
    Configuration.isMissingRequiredQueries
  )

  const loadingProviders = ReactRedux.useSelector((state) =>
    Processes.isRunning(state.processes, {
      ids: [
        'brite/fetch-payments-methods-by-user',
        'zimpler/fetch-payments-methods-by-user',
        'piq/fetch-payments-methods-by-user',
        'piqkyc/fetch-payments-methods-by-user',
        'projs/fetch-payments-methods-by-user',
        'pwclick/fetch-payments-methods-by-user',
        'transferworld/fetch-payments-methods-by-user',
        'helloclever/fetch-payments-methods-by-user',
      ],
    })
  )

  React.useEffect(() => {
    // There will be no processes running on the first render so this check
    // prevents the application from starting autosubmittion and firing errors down the tree.
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (!loadingProviders) {
      setLoading(false)
    }
  }, [loadingProviders])

  React.useEffect(() => {
    // Lets host know that we're ready for data
    dispatch(Data.initiated())
  }, [dispatch])

  React.useEffect(() => {
    fetchServerSideVersion()
      .then((res) => {
        if (res.version !== process.env.RAZZLE_APP_VERSION) {
          dispatch(Data.toggleNewVersion())
        }
      })
      // Ignore fetch errors
      .catch(() => {})
  }, [dispatch])

  React.useEffect(() => {
    // Dispatches name of current step
    if (step) {
      const stepName = R.find(
        (item) => R.equals(step, Constants.TransactionStep[item]),
        R.keys(Constants.TransactionStep)
      )
      dispatch(Data.stepChanged({step: stepName}))

      scrollTo(0, 0)
    }
  }, [step, dispatch])

  if (!R.includes(transactionType, Constants.acceptableTransactionTypes)) {
    return <UnsupportedTransactionType />
  }

  if (isMissingRequiredQueries) {
    return <MissingQueries />
  }

  return (
    <ThemeUi.Box
      sx={{
        'flex': '1',
        'display': 'flex',
        '> form': {
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          px: R.equals(step, Constants.TransactionStep.Processing) ? '0px' : 0,
          pb: R.equals(step, Constants.TransactionStep.Processing) ? '0px' : 1,
        },
      }}
    >
      <Payments.TransactionProvider
        attributes={R.merge(attributes, {
          failure_url: `${origin}/callbacks/transaction-failure`,
          success_url: `${origin}/callbacks/transaction-success`,
          pending_url: `${origin}/callbacks/transaction-pending`,
          cancel_url: `${origin}/callbacks/transaction-failure`,
        })}
        mountPoint="payments"
        transactionType={transactionType}
        onCancel={() => setStep(Constants.TransactionStep.Cancel)}
        onFailure={() => setStep(Constants.TransactionStep.Failure)}
        onSuccess={() => setStep(Constants.TransactionStep.Success)}
      >
        <Forms.Provider
          schema={dataSchema}
          name="transaction"
          onSubmit={(errors, data) => {
            if (R.isEmpty(errors) && data.cryptoCurrency) {
              dispatch(Data.cryptoCurrencySelected(data.cryptoCurrency))
              return setStep(Constants.TransactionStep.Processing)
            }

            if (R.isEmpty(errors)) {
              return setStep(Constants.TransactionStep.Processing)
            }

            if (!data.cryptoCurrency && (data.provider || data.accountId)) {
              return setStep(Constants.TransactionStep.PaymentDetails)
            }
          }}
        >
          {loading ? (
            Suspense.Fallbacks.PageWideSpinner
          ) : (
            <React.Fragment>
              <Suspense.Boundary fallback={null}>
                <Menu step={step} onStepChange={setStep} />
              </Suspense.Boundary>
              <TransactionConsumer step={step} onStepChange={setStep} />
            </React.Fragment>
          )}
        </Forms.Provider>
      </Payments.TransactionProvider>
    </ThemeUi.Box>
  )
}

/**
 * Helper function for manually selecting some providers to auto-skip certain steps
 * @param {Object} data All form-fields data
 * @param {string[]} errors All form errors
 * @param {number} step Current transaction step
 * @returns {boolean} If form should submit
 */
function shouldAutoSubmit(data, errors, step) {
  const isValid = R.isEmpty(errors)
  const paymentMethodsIds = [
    'PiqAstropaycardGoDeposit',
    'PiqAstropaycardNbDeposit',
    'PiqAstropaycardPhDeposit',
    'PiqAstropaycardTmDeposit',
    'PiqAstropaycardUiDeposit',
    'PiqBankBlDeposit',
    'PiqBankIxDeposit',
    'PiqBankBriteDeposit',
    'PiqBankZimplerDeposit',
    'PiqBankInovapayDeposit',
    'PiqBankInteracDeposit',
    'PiqBankPixDeposit',
    'PiqBankNetbankingDeposit',
    'PiqBankRedeemVoucherDeposit',
    'PiqBankBtvoucherDeposit',
    'PiqBankBtvoucherAtmDeposit',
    'PiqBankPpbtvoucherDeposit',
    'PiqBankVoltDeposit',
    'PiqBankVoltWithdrawal',
    'PiqBankParamountDeposit',
    'PiqBankibanOnlineueberweisenDeposit',
    'PiqBankBriteWithdrawal',
    'PiqBankZimplerWithdrawal',
    'PiqMifinityDeposit',
    'PiqkycTrustlyAuth',
    'PiqkycTrustlyDeposit',
    'PiqPproIdealDeposit',
    'PiqSofortDeposit',
    'PiqWebredirectOpDeposit',
    'PiqWebredirectPoliDeposit',
    'PiqWebredirectApplepayDeposit',
    'PiqWebredirectGooglepayDeposit',
    'PiqWebredirectOnlineBankingDeposit',
    'PiqWebredirectQrpaymentQuicktransferDeposit',
    'PiqWebredirectBanktransferQuicktransferDeposit',
    'PiqWebredirectUpiQuicktransferDeposit',
    'PiqWebredirectP2pQuicktransferDeposit',
    'PiqWebredirectEbpDeposit',
    'PiqWebredirectMacropayDeposit',
    'PiqWebredirectNodapayDeposit',
    'PiqWebredirectBoletoDeposit',
    'PiqWebredirectBoletoPixDeposit',
    'PiqWebredirectBtDeposit',
    'PiqWebredirectFlykkDeposit',
    'PiqWebredirectFinsupportDeposit',
    'PiqWebredirectJpayDeposit',
    'PiqWebredirectOnrampDeposit',
    'PiqWebredirectPagavaDeposit',
    'PiqWebredirectPneDeposit',
    'PiqWebredirectBdbanksDeposit',
    'PiqWebredirectUpiDeposit',
    'PiqWebredirectRupayDeposit',
    'PiqWebredirectOnlineDebitDeposit',
    'PiqWebredirectDirectPaymentDeposit',
    'PiqWebredirectUpiH5Deposit',
    'PiqWebredirectPaytmWalletDeposit',
    'PiqWebredirectPhonepeWalletDeposit',
    'PiqWebredirectMobikwikWalletDeposit',
    'PiqWebredirectAirtelWalletDeposit',
    'PiqWebredirectJioWalletDeposit',
    'PiqWebredirectCreditCardDeposit',
    'PiqWebredirectUpiWithdrawal',
    'PiqWebredirectLocalBankTransferWithdrawal',
    'PiqWebredirectMastercardDeposit',
    'PiqWebredirectVisaDeposit',
    'PiqWebredirectPixOnlineDeposit',
    'PiqWebredirectQuickbitDeposit',
    'PiqWebredirectSofortDeposit',
    'PiqWebredirectSticpayDeposit',
    'PiqWebredirectSumopayDeposit',
    'PiqWebredirectZotacardDeposit',
    'PiqWebredirectOnrampWithdrawal',
    'PiqWebredirectQaicashDeposit',
    'PiqWebredirectQaicashccDeposit',
    'PiqWebredirectQaicashWithdrawal',
    'PiqWebredirectPointinoutDeposit',
    'PiqWebredirectQpointsDeposit',
    'PiqWebredirectPointinoutWithdrawal',
    'PiqWebredirectQpointsWithdrawal',
    'PiqWebredirectIbDeposit',
    'PiqWebredirectZotapayDeposit',
    'PiqWebredirectPaytmDeposit',
    'PiqWebredirectImpsDeposit',
    'PiqWebredirectGpayDeposit',
    'PiqWebredirectPhonepeDeposit',
    'PiqWebredirectTigerpayDeposit',
    'PiqWebredirectBtbDeposit',
    'PiqWebredirectBtbAutoDeposit',
    'PiqWebredirectTigerpayWithdrawal',
    'PwclickPwclickDeposit',
    'PiqMifinityUnionpayDeposit',
    'PiqMifinityKlarnaDeposit',
    'PiqWebredirectSparkasseDeposit',
    'PiqWebredirectVolksbankenDeposit',
    'PiqWebredirectDeutschebankDeposit',
    'PiqWebredirectPostbankDeposit',
    'PiqWebredirectCommerzbankDeposit',
    'PiqWebredirectTrustlyusDeposit',
    'PiqWebredirectDeutschekreditbankDeposit',
    'PiqWebredirectGate2wayDeposit',
    'PiqWebredirectGate2wayskrillDeposit',
    'PiqWebredirectGate2waynetellerDeposit',
    'PiqWebredirectGate2waygiropayDeposit',
    'PiqWebredirectGate2waypaysafecardDeposit',
    'PiqWebredirectGate2waypaysafecashDeposit',
    'PiqWebredirectGate2waysofortDeposit',
    'PiqWebredirectGate2waywebpayzDeposit',
    'TransferworldTransferworldDeposit',
    'PayustPayustBankTransferDeposit',
    'PayustPayustPaparaDeposit',
    'PayustPayustPaybolDeposit',
    'PayustPayustParaKolayDeposit',
    'PayustPayustUnipaymentDeposit',
    'PayustPayustStarpayzPaybolDeposit',
    'PayustPayustCreditCardDeposit',
    'PayustPayustPayfixDeposit',
    'VevopayVevopayPaparaDeposit',
    'VevopayVevopayKredikartiDeposit',
    'VevopayVevopayHavaleDeposit',
    'VevopayVevopayMefeteDeposit',
    'VevopayVevopayPayfixDeposit',
    'VevopayVevopayParazulaDeposit',
    'VevopayVevopayCmtDeposit',
    'VevopayVevopayPopyDeposit',
  ]

  const paymentMethodsWithAccount = [
    'PiqBankibanBriteWithdrawal',
    'PiqBankibanZimplerWithdrawal',
  ]

  if (step === Constants.TransactionStep.PaymentDetails) {
    if (
      R.includes(data.provider, paymentMethodsWithAccount) &&
      !R.isEmpty(data.accountId)
    ) {
      return isValid
    }
    if (R.includes(data.provider, paymentMethodsIds)) {
      return isValid
    }
  }

  if (step === Constants.TransactionStep.PaymentMethod) {
    const hasAccount = data && data.accountId != null && data.provider != null
    return hasAccount || isValid
  }

  return false
}

/**
 * Uses data from provider and renders component that handles data for each step
 * @component TransactionConsumer
 * @param {Object} props Components props
 * @param {number} props.step Current transaction step
 * @param {func} props.onStepChange callback to change transaction step
 * @returns {ReactNode} Transaction Consumer
 */
function TransactionConsumer(props) {
  const {transactionType} = Payments.useTransactionContext()
  const dispatch = ReactRedux.useDispatch()
  const form = Forms.useFormContext()
  const data = Forms.getFormData(form.state)
  const errors = Forms.getFormErrors(form.state)
  const prevStep = Hooks.usePrev(props.step)
  const amountCents = ReactRedux.useSelector(Configuration.getAmountCents)
  const userId = ReactRedux.useSelector(Configuration.getUserId)

  useTransactionLogging({
    paymentMethod: data?.provider,
    step: props.step,
    transactionType,
    userId,
  })

  // Puts amount into form-state
  Forms.useField('#/properties/amount', {
    initialValue:
      transactionType === Payments.TransactionType.AUTH ? 0 : amountCents,
  })

  React.useEffect(() => {
    // If user went back, clear these fields so next submit doesn't trigger with old values
    if (prevStep > props.step) {
      // If we were processing a request when user went back, reset transaction-state
      if (prevStep === Constants.TransactionStep.Processing) {
        dispatch(Payments.resetTransaction())
      }
      // Clears all info entered when going back. Prevents errors displaying on no action taken and
      // "false submition" when entering details with prefilled, or previously filled data
      form.destroyFields({
        exclude: ['#/properties/amount'],
      })
    } else if (shouldAutoSubmit(data, errors, props.step)) {
      form.submit()
    }
  }, [data, dispatch, props.step, errors, form, prevStep])

  return (
    <Suspense.Boundary>
      {Constants.TransactionStep.PaymentMethod === props.step && (
        <Animations.FadeInOut>
          <PaymentMethods />
        </Animations.FadeInOut>
      )}
      {Constants.TransactionStep.PaymentDetails === props.step && (
        <Animations.FadeInOut>
          <PaymentDetails />
        </Animations.FadeInOut>
      )}
      {Constants.TransactionStep.Processing === props.step && (
        <Animations.FadeInOut>
          <TransactionProcessing onStepChange={props.onStepChange} />
        </Animations.FadeInOut>
      )}
      {Constants.TransactionStep.Success === props.step && (
        <Animations.FadeInOut>
          <Success />
        </Animations.FadeInOut>
      )}
      {Constants.TransactionStep.Failure === props.step && (
        <Animations.FadeInOut>
          <Failure onStepChange={props.onStepChange} />
        </Animations.FadeInOut>
      )}
      {Constants.TransactionStep.Cancel === props.step && (
        <Animations.FadeInOut>
          <Cancel />
        </Animations.FadeInOut>
      )}
      {Constants.TransactionStep.Pending === props.step && (
        <Animations.FadeInOut>
          <Pending />
        </Animations.FadeInOut>
      )}
    </Suspense.Boundary>
  )
}

TransactionConsumer.propTypes = {
  step: PropTypes.number,
  onStepChange: PropTypes.func.isRequired,
}

// For @loadable/component
export default Transaction
